import { useStaticQuery, graphql } from "gatsby"
export const useAllWordpressCaseStudies = () => {
  return useStaticQuery(
    graphql`
    query AllWordpressCaseStudies {
      allWordpressWpCaseStudies {
        nodes {
          categories {
            wordpress_id
          }
        }
      }
    }
  `)
}