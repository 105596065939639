import { useStaticQuery, graphql } from "gatsby"
export const useAllWordpressCategory = () => {
  return useStaticQuery(
    graphql`
    query AllWordpressCategory {
      allWordpressCategory {
        nodes {
          name
          slug
          id
          wordpress_id
        }
      }
    }
  `)
}