import { useStaticQuery, graphql } from "gatsby"
export const useAllWordpressPost = () => {
  return useStaticQuery(
    graphql`
    query AllWordpressPost {
      allWordpressPost {
        nodes {
          categories {
            wordpress_id
          }
        }
      }
    }
  `)
}