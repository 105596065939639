import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import GatsbyLink from '../../GatsbyLink'
import { useAllWordpressCategory } from '../../__HOOKS/use-all-wp-category'
import { useAllWordpressCaseStudies } from '../../__HOOKS/use-all-wp-casestudy'
import { useAllWordpressPost } from '../../__HOOKS/use-all-wp-post'
import './PostListNavigation.scss'

const ListNavigation = props => {
  const {
    data,
    customPostTypeData,
    pathPrefix,
    customPostTypePathPrefix,
    wordpressPostData
  } = props
  const { allWordpressCategory = {} } = data

  let { nodes: categories } = allWordpressCategory
  /* Let's extract the wordpress_ids from the case study for matching the categories in 
     the filter.
   */
  let caseStudyCategories = [];
  if (props.customPostTypeData) {
    const { nodes }  = customPostTypeData.allWordpressWpCaseStudies
    let tempArray = []
    // Loop through each node in the wordpress case study query data
    nodes.forEach(node => {
      // Loop through each category on the node.
      for (let i = 0; i < node.categories.length; i++) {
        // check if the temp array includes the id already
        if (!tempArray.includes(node.categories[i].wordpress_id)) {
          // push the value of the wordpress_id to the temporary array.
          tempArray.push(node.categories[i].wordpress_id)
          // Finally push the node category: wordpress_id to the casestudy category filter
          caseStudyCategories.push(node.categories[i])
        }
      }
    })
  }

  let blogPostCategories = [];
  if (props.wordpressPostData) {
    const { nodes } = wordpressPostData.allWordpressPost;
    let tempArray = []
    nodes.forEach(node => {
      for (let i = 0; i < node.categories.length; i++) {
        if (!tempArray.includes(node.categories[i].wordpress_id)) {
          tempArray.push(node.categories[i].wordpress_id)
          blogPostCategories.push(node.categories[i])
        }
      }
    })
  }
  /*
   * We are going to sort through the all of the custom post type allWordpressCaseStudies
   * and return the category.wordpress_id that this custom post type contains.
   * With category.wordpress_id we are going to filter and return the nodes on allWordpressCategory that
   * have the wordpress_id in the case studies, so that we only see matching nodes for the case study post type.
   */
  const caseStudyMatchingCategories =
    caseStudyCategories &&
    caseStudyCategories.map(caseStudy =>
      allWordpressCategory.nodes.find(
        allCategoryNode =>
          allCategoryNode.wordpress_id == caseStudy.wordpress_id
      )
    )
  
  const blogMatchingCategories =
    blogPostCategories &&
    blogPostCategories.map(blogPost =>
      allWordpressCategory.nodes.find(
        allCategoryNode =>
          allCategoryNode.wordpress_id == blogPost.wordpress_id
      )
    )

  /*
   * Set your pathprefix to the customPostTypePathPrefix and check for it
   * to filter for the matching categories
   */
  if (pathPrefix === customPostTypePathPrefix) {
    categories = caseStudyMatchingCategories
  }

  /* Hiding the filter option on the blog post page */
  let showBlogFilter = true;
  if (pathPrefix === 'blog') {
    categories = blogMatchingCategories
    showBlogFilter = false;
  }

  if (showBlogFilter) {
    return (
      <div className="post-list-navigation">
        <div className="inner">
          <strong>Filter by: </strong>
          <ul>
            <li>
              <GatsbyLink to={`/${pathPrefix}/`} activeClassName="active">
                All
              </GatsbyLink>
            </li>
            {categories &&
              categories.map(
                (category, index) =>
                  category.slug !== 'uncategorized' && category.slug !== 'gatsby-placeholder' && (
                    <li key={index}>
                      <GatsbyLink
                        to={`/${pathPrefix}/${category.slug}/`}
                        activeClassName="active"
                        dangerouslySetInnerHTML={{ __html: category.name }}
                      />
                    </li>
                  )
              )}
          </ul>
        </div>
      </div>
    )
  } else return null; // basically just return null if it's the blog page.
}
// Filter by passed query to customPostTypeData prop.
export const PostListNavigation = props => {
  const data = useAllWordpressCategory()
  const wordpressPostData = useAllWordpressPost()
  /*  You need to define your custom post type with a hook, or with another
      method of retrieving the data. */
  const caseStudyData = useAllWordpressCaseStudies() || null
  const customPathPrefix = 'case-studies' // Set your path prefix to the custom post type path-prefix
  /*  We are passing the custom post type case study data, which will only be looked at if the path-prefix is equal
      to the custom post type. */
  return (
    <ListNavigation
      {...props}
      data={data}
      customPostTypeData={caseStudyData}
      customPostTypePathPrefix={customPathPrefix}
      wordpressPostData={wordpressPostData}
    />
  )
}
