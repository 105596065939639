import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import Pagination from '../Pagination';
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'
import './ProjectList.scss';

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categories, pathPrefix, siteMetadata, slug} = this.props

    return (
      <section className="project-list">
        <PostListNavigation pathPrefix={pathPrefix} />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map((slide, index) => {
                  return (
                    <PostListItem key={index} data={slide.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} />
                  )
                })}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={`/${pathPrefix}/`} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_case_studies {
    id
    categories {
      id
      name
      slug
    }
    wordpress_id
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    featured_image_url {
      source_url
    }
  }
`
